<template lang="html">
  <div>
    <!--  -->
    <section class="container" v-if="content && content._embedded['wp:featuredmedia']['0']" style="height:360px;overflow:hidden">
      <div class="my-widget--slide w3-display-container">
        <img src="../../assets/logo-white.png" alt="" class="my-widget--placeholder w3-display-middle">
        <img :src="content._embedded['wp:featuredmedia']['0'].source_url" alt="" class="my-widget--slide-content lazyload">
      </div>
    </section>
    <!-- <section class="container">
      <Carousel :perPage="1" :autoplay="true" :autoplayTimeout="5000" paginationActiveColor="#CF9405">
        <Slide v-for="image in content.covers" :key="image">
          <div class="my-widget--slide w3-display-container">
            <img src="../../assets/logo-white.png" alt="" class="my-widget--placeholder w3-display-middle">
            <img :data-src="image" alt="" class="my-widget--slide-content lazyload">
          </div>
        </Slide>
      </Carousel>
    </section> -->

    <!-- Navigation -->
    <section class="container my-3">
      <ul class="nav nav-pills widget--nav-tabs text-uppercase widget--subtitle font-medium" role="tablist">
        <li class="nav-item">
          <a href="#overview" aria-selected="true" aria-controls="overview" class="nav-link active" role="tab" data-toggle="tab">
            <span v-if="french">Sommaire</span>
            <span v-if="english">Overview</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#publications" aria-selected="false" aria-controls="publications" class="nav-link" role="tab" data-toggle="tab">Publications
          </a>
        </li>
        <li class="nav-item">
          <a href="#team" aria-selected="false" aria-controls="team" class="nav-link" role="tab" data-toggle="tab">
          <span class="text-uppercase" v-if="french">équipe</span>
          <span class="text-uppercase" v-if="english">team</span>
        </a></li>
      </ul>
    </section>

    <!--  -->
    <section class="container mt-4">
      <div class="row">
        <div class="col-12 col-md-8 pr-md-5">
          <!-- Tab panes -->
          <div class="tab-content" v-if="content && content.acf">
            <!-- Overview - Sommaire -->
            <article role="tabpanel" class="tab-pane fade show active text-justify" id="overview" aria-labelledby="overview-tab">
              <h2 class="page-header mt-0">
                <strong v-if="english">{{content.title.rendered}}</strong>
                <strong v-if="french">{{content.title.rendered}}</strong>
              </h2>
              <!-- Content -->
              <p class="text-justify" v-html="content.acf.contenu_en" v-if="english"></p>
              <p class="text-justify" v-html="content.acf.contenu_fr" v-if="french"></p>
              <!-- Address -->
                <div class="p-4 mt-5 widget--gradient widget--border-right-bottom" style="border-left:8px solid #CF9405!important;">
                  <!-- English -->
                  <div v-if="english" v-html="content.acf.adresse_en"></div>
                  <div v-if="french" v-html="content.acf.adresse_fr"></div>
                </div>
            </article>
            <!-- Publications -->
            <article role="tabpanel" class="tab-pane fade" id="publications" aria-labelledby="publications-tab">

            </article>
            <!-- Team -->
            <article role="tabpanel" class="tab-pane fade" id="team" aria-labelledby="team-tab">
              <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="panel panel-default secondary--background widget--presence-experts widget--borderless mb-5" v-for="(expert, i) in experts" :key="expert.id" v-if="(i+1) % 2">
                      <div class="panel-body p-3 row">
                      <!-- <div class="panel-body p-2 row" style="min-height: 180px!important"> -->
                        <!-- Picture -->
                        <div class="col-6 col-md-5">
                          <router-link :to="`/profile/${expert.slug}`">
                            <img :data-src="expert._embedded['wp:featuredmedia']['0'].source_url" alt="" class="lazyload w3-round widget--expert-img" v-if="expert._embedded['wp:featuredmedia']['0']">
                            <div class="widget--expert-img-placeholder d-flex flex-row align-items-center justify-content-center" v-else>
                              <img src="../../assets/logo-white.png" alt="">
                            </div>
                          </router-link>
                        </div>
                        <!-- Info -->
                        <div class="col-6 col-md-7 py-1 pl-1 caption main-color">
                          <router-link :to="`/profile/${expert.slug}`" class="subheading medium pointer">
                            {{expert.title.rendered}}
                          </router-link>
                          <div class="text-muted mt-1" style="font-size:13.5px">
                              <div class="_flex mb-3">
                                <i class="icon-pin mr-2" aria-hidden="true"></i>
                                <span v-if="french">{{expert.acf.grade_fr}}</span>
                                <span v-if="english">{{expert.acf.grade_en}}</span>
                              </div>
                              <!-- <div class="_flex">
                                {{expert.location_1}} <span v-if="expert.location_2">• {{expert.location_2}}</span> <span v-if="expert.location_3">• {{expert.location_3}}</span>
                              </div> -->
                              <div class="_flex mb-1">
                                <span class="icon-location-pin mr-1" aria-hidden="true"></span>
                                <span v-for="office in expert.acf.office" :key="office.term_id" class="office--span">{{office.name}}</span>
                              </div>
                              <!-- <div class="_flex">
                                {{expert.phone_1}} <span v-if="expert.phone_2"> • {{expert.phone_2}}</span> <span v-if="expert.phone_3"> • {{expert.phone_3}}</span>
                              </div> -->
                              <div class="_flex" v-if="expert.acf.email">
                                <i class="icon-envelope-open mr-2" aria-hidden="true"></i>
                                {{expert.acf.email}}
                              </div>
                          </div>
                          <router-link :to="`/profile/${expert.slug}`"  class="mt-2 _flex" style="font-size:13.5px">
                              <svg aria-hidden="true" focusable="false" width="12px" height="12px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                  </path>
                              </svg>
                              <span class="animated-underline ml-1 medium fg-primary">
                                <span v-if="french">Voir profil</span>
                                <span v-if="english">See profile</span>
                              </span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="panel panel-default secondary--background widget--presence-experts widget--borderless mb-5" v-for="(expert, i) in experts" :key="expert.id" v-if="(i+2) % 2">
                      <div class="panel-body p-3 row">
                        <!-- Picture -->
                        <div class="col-6 col-md-5">
                          <router-link :to="`/profile/${expert.slug}`">
                            <img :data-src="expert._embedded['wp:featuredmedia']['0'].source_url" alt="" class="lazyload w3-round widget--expert-img" v-if="expert._embedded['wp:featuredmedia']['0']">
                            <div class="widget--expert-img-placeholder d-flex flex-row align-items-center justify-content-center" v-else>
                              <img src="../../assets/logo-white.png" alt="">
                            </div>
                          </router-link>
                        </div>
                        <!-- Info -->
                        <div class="col-6 col-md-7 py-1 pl-1 caption main-color">
                          <router-link :to="`/profile/${expert.slug}`" class="subheading medium pointer">
                            {{expert.title.rendered}}
                          </router-link>
                          <div class="text-muted mt-1" style="font-size:13.5px">
                              <div class="_flex mb-3">
                                <i class="icon-pin mr-2" aria-hidden="true"></i>
                                <span v-if="french">{{expert.acf.grade_fr}}</span>
                                <span v-if="english">{{expert.acf.grade_en}}</span>
                              </div>
                              <!-- <div class="_flex">
                                {{expert.location_1}} <span v-if="expert.location_2">• {{expert.location_2}}</span> <span v-if="expert.location_3">• {{expert.location_3}}</span>
                              </div> -->
                              <div class="_flex mb-1">
                                <span class="icon-location-pin mr-1" aria-hidden="true"></span>
                                <span v-for="office in expert.acf.office" :key="office.term_id" class="office--span">{{office.name}}</span>
                              </div>
                              <!-- <div class="_flex">
                                {{expert.phone_1}} <span v-if="expert.phone_2"> • {{expert.phone_2}}</span> <span v-if="expert.phone_3"> • {{expert.phone_3}}</span>
                              </div> -->
                              <div class="_flex" v-if="expert.acf.email">
                                <i class="icon-envelope-open mr-2" aria-hidden="true"></i>
                                {{expert.acf.email}}
                              </div>
                          </div>
                          <router-link :to="`/profile/${expert.slug}`"  class="mt-2 _flex" style="font-size:13.5px">
                              <svg aria-hidden="true" focusable="false" width="12px" height="12px" fill="currentColor" viewBox="0 0 36 36" class="widget--hover-brand-color widget--arrow">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor">
                                  </path>
                              </svg>
                              <span class="animated-underline ml-1 medium fg-primary">
                                <span v-if="french">Voir profil</span>
                                <span v-if="english">See profile</span>
                              </span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </article>
          </div>
          <!-- Share -->
          <Share :link="$route.fullPath" />
        </div>
        <!-- Sidebar -->
        <div class="col-12 col-md-4">
          <Sidebar />
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Learn more -->
    <section v-if="recommandations.length">
      <div class="container _flex _between mt-5 mb-3">
        <h5 class="widget--see-more">
          <span v-if="french">Voir aussi</span>
          <span v-if="english">See more</span>
        </h5>
        <div class="">
          <a @click="scrollRight" class="widget--hover-brand-color pointer"><i class="fal fa-angle-left mr-4 w3-xxlarge"></i></a>
          <a @click="scrollLeft" class="widget--hover-brand-color pointer"><i class="fal fa-angle-right w3-xxlarge"></i></a>
        </div>
      </div>
      <div class="primary--background">
        <div class="container py-4">
          <div class="d-flex flex-nowrap flex-row align-items-center widget--more" id="widget--more">
            <div class="widget--more-box" v-for="item in recommandations" :key="item.id">
              <div class="widget--more-boxing">
                <h5 class="my-0">
                  <strong v-if="french">{{item.title.rendered}}</strong>
                  <!-- <strong v-if="english">{{item.title_en}}</strong> -->
                </h5>
                <router-link tag="button" type="button" :to="`/presence/${item.slug}`" class="btn widget--brand widget--radius-link mr-4 widget--position">
                  <i class="fal fa-angle-right mr-2" aria-hidden="true"></i>
                  <span v-if="french">Lire plus</span>
                  <span v-if="english">Read more</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { languageMixin } from '../../mixins/language'
import axios from "axios"

export default {
  mixins: [languageMixin],
  data: () => ({
    slug: null,
    lawyers: [],
    images: [],
    content: null
  }),
  computed: {
    ...mapGetters(['presences', 'newExperts']),
    experts () {
      if (this.content) {
        let data = [];
        this.content.acf.tag.forEach(tag => {
          data = this.newExperts.filter(expert => {
            return expert.acf.office ? expert.acf.office.find(off => off.slug === tag.slug) : null
          });
          return data;
        });
        return data;
      }
    },
    // content() {
    //   return this.$store.getters.getPresence(this.id)
    // },
    recommandations () {
      // return []
      if (this.content && this.content.id) {
        return this.presences.length ? this.presences.filter(option => option.id != this.content.id) : []

      } else {
        return []
      }
    },
  },
  watch: {
    '$route' (to, from) {
      if (this.slug !== to.params.slug) {
        this.content = null;
          this.getContent();
          this.$forceUpdate();
      }
    },
  },
  methods: {
    getContent () {
      // return this.$store.getters.getPresence(this.id)
      this.content = null;
      return axios
        .get(
          `https://admin.cabemery.org/wp-json/wp/v2/bureau?slug=${this.$route.params.slug}&_embed`
        )
        .then((result) => {
          this.content = result.data[0];
          console.log("Presence: ", this.content)
        })
        .catch((e) => {
          console.error(e);
        });
    },
    goto(value) {
      this.$router.push({
        path: this.english ? value.url + value.data.url_en : value.url + value.data.url_fr,
        query: { id: value.data.id }
      })
    },
  },
  created() {
    // this.id = this.$route.query.id
    this.slug = this.$route.params.slug;
    this.getContent();
    if (!this.newExperts.length) {
      this.$store.dispatch('getExperts')
    }
  }
}
</script>

<style lang="css">
</style>
